import { IS_LOGIN, ADD_TAG, DELETE_TAG, CLOSE_ALL, CLOSE_OTHER, SET_TOKEN, IS_ROOT, MENU_PERMIS, OPERATE_PERMIS, USERNAME, USERINFO } from './actionTypes'

export default (state: any, action: any) => {
  switch (action.type) {
    case IS_LOGIN:
      state.isLogin = action.value
      sessionStorage.isLogin = state.isLogin
      return state
    case SET_TOKEN:
      state.token = action.value
      sessionStorage.token = state.token
      return {...state}
    case ADD_TAG:
      if (state.tags.length) {
        let tags = state.tags.map((it: any) => {return it.pathname})
        let index = tags.indexOf(action.value.pathname)
        if (index !== -1) {
          state.tags[index] = action.value
        } else {
          state.tags.push(action.value)
        }
      } else {
        state.tags.push(action.value)
      }
      sessionStorage.tags = JSON.stringify(state.tags)
      return {...state}
    case DELETE_TAG:
      state.tags.splice(action.value, 1)
      sessionStorage.tags = JSON.stringify(state.tags)
      return {...state}
    case CLOSE_ALL:
      let tag: any
      state.tags.some((item: any) => {
        if (item.pathname === '/') {
          tag = item
        }
      })
      state.tags = [tag]
      sessionStorage.tags = JSON.stringify(state.tags)
      return {...state}
    case CLOSE_OTHER:
      let tags: any = []
      state.tags.forEach((item: any) => {
        if (item.pathname === '/' || item.pathname === action.value) {
          tags.push(item)
        }
      })
      state.tags = [...tags]
      sessionStorage.tags = JSON.stringify(state.tags)
      return {...state}
    case IS_ROOT:
      state.isRoot = action.value
      sessionStorage.isRoot = state.isRoot
      return {...state}
    case MENU_PERMIS:
      state.menuPermissions = action.value
      sessionStorage.menuPermissions = JSON.stringify(state.menuPermissions)
      return {...state}
    case USERNAME:
      state.userName = action.value
      sessionStorage.userName = state.userName
      return {...state}
    case USERINFO:
      state.userInfo = action.value
      sessionStorage.userInfo = JSON.stringify(state.userInfo)
      return {...state}
    case OPERATE_PERMIS:
      state.operatePermissions = action.value
      sessionStorage.operatePermissions = JSON.stringify(state.operatePermissions)
      return {...state}
    default:
      return state
  }
}