export const IS_LOGIN = 'isLogin' // 登录状态
export const ADD_TAG = 'addTag' // 添加TAG
export const DELETE_TAG = 'deleteTag' // 删除TAG
export const CLOSE_ALL = 'closeAll' // 关闭所有
export const CLOSE_OTHER = 'closeOther' // 关闭其他
export const SET_TOKEN = 'setToken' // 写入token
export const IS_ROOT = 'isRoot' // 是否为超管
export const MENU_PERMIS = 'menuPermissions' // 菜单权限
export const OPERATE_PERMIS = 'operatePermissions' // 按钮权限
export const USERNAME = 'userName'
export const USERINFO = 'userInfo'