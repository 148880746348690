export const base_url = process.env.NODE_ENV === "testbuild" ? 'https://testclient.kuanjie.work/api/admin/' :
      process.env.NODE_ENV === "prodbuild" ? 'https://shunbeiwebapp.bluejingyu.com/api/admin/' : 'https://testclient.kuanjie.work/api/admin/'
      
export const IMBaseUrl = process.env.NODE_ENV === "testbuild" ? 'https://testgw.kuanjie.work' :
  process.env.NODE_ENV === 'prodbuild' ? 'https://gateway.kuanjie.work' : 'https://testgw.kuanjie.work'

// export const fromUrl = process.env.NODE_ENV === "testbuild" ? 'http://login.yshome.com/#/Login' :
//       process.env.NODE_ENV === "prodbuild" ? 'https://login.kuanjie.work/#/Login' : 'http://login.yshome.com/#/Login'

export const imgUrl = process.env.NODE_ENV === "testbuild" ? 'https://testclient.kuanjie.work/api/admin/upload/image' :
      process.env.NODE_ENV === "prodbuild" ? 'https://managerapi.kuanjie.work/api/admin/upload/image' : 'https://testclient.kuanjie.work/api/admin/upload/image'


export const validateURL = (textval: string) => { /* 合法uri */
      const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
      return urlregex.test(textval)
}
export const IsBtnRoot = (root: string) => { // 验证是否有权限
      const rootList = sessionStorage.operatePermissions ? JSON.parse(sessionStorage.operatePermissions) : []
      const isRoot = Number(sessionStorage.isRoot)
      if (rootList.includes(root) || isRoot === 1) return true
      else return false
}